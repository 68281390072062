<template>
  <div>
    <b-card title="Dönem Seçimi">
      <b-row class="d-flex align-content-center align-items-center">
        <b-col>
          <b-form-group
            label="Durum"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="filterData.status"
              :options="statuses"
              :reduce="status => status.id"
              label="title"
              placeholder="Durum Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="filterData.sdate"
              v-bind="{labelNoDateSelected: 'Gün',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="filterData.edate"
              v-bind="{labelNoDateSelected: 'Gün',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Danışman"
            label-for="id_com_user"
          >
            <v-select
              id="id_com_user"
              v-model="filterData.id_com_user"
              :options="users"
              :reduce="user => user.id"
              label="title"
              placeholder="Seçiniz"
            >
              <template v-slot:option="option">
                <div v-if="option.user_type">
                  {{ option.title }}
                  <div>
                    <small class="text-warning">{{ option.user_type }}</small>
                  </div>
                </div>
                <div v-else>
                  {{ option.title }}
                </div>
              </template>
              <template v-slot:selected-option="option">
                {{ option.title }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            @click="getReport"
          >
            Verileri Getir
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import {
  BButton, BCard, BCol, BFormDatepicker, BFormGroup, BRow,
} from 'bootstrap-vue'

export default {
  name: 'ReminderFilterData',
  components: {
    BCol, BFormGroup, BRow, BCard, BButton, BFormDatepicker, vSelect,
  },
  props: {
    getReport: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      statuses: [
        {
          id: 2,
          title: 'Aranmamışlar',
        },
        {
          id: 1,
          title: 'Aranmışlar',
        },
      ],
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['insuranceReminderReport/getFilterData']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      const where = {
        'com_user.id_com_brand': 8,
        'com_user.status': 1,
      }
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id          AS id',
          'com_user.name        AS title',
          'com_user_type.title  AS user_type',
        ],
        where,
      })
    },
  },
}
</script>
