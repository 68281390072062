<template>
  <div>
    <reminder-filter-data :get-report="getReport" />
    <template v-if="reportLoading">
      <pre-loading message="Rapor Hazırlanıyor.." />
    </template>
    <template v-else>
      <insurance-reminder-report-statistics />
      <b-card
        v-if="reportData.length > 0"
        no-body
      >
        <b-card-header>
          <b-card-title>
            Liste
          </b-card-title>
          <b-button
            variant="primary"
            :href="downloadURL"
          >
            <feather-icon icon="DownloadIcon" />
            Excel İndir
          </b-button>
        </b-card-header>
        <b-table
          :fields="fields"
          :items="reportData"
          striped
          hover
          small
        >
          <template #cell(callCount)="data">
            <span class="text-primary font-weight-bold">{{ data.item.callCount }}</span>
            <div v-if="data.item.id_com_interview">
              <b-link
                :to="'/interviews/view/' + data.item.interview_number"
                target="_blank"
                class="font-weight-bold"
              >
                Görüşme kaydı mevcut
              </b-link>
            </div>
            <div v-else>
              <small class="text-warning">Arama Bekliyor</small>
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
      <b-alert
        v-else
        show
        variant="info"
        class="m-2"
      >
        <div class="alert-body text-center">
          Görüntülenecek veri bulunmuyor.
        </div>
      </b-alert>
    </template>
  </div>
</template>
<script>
import {
  BCard,
  BCardTitle,
  BCardHeader,
  BPagination,
  BTable,
  BCardFooter, BAlert, BLink,
  BButton,
} from 'bootstrap-vue'
import ReminderFilterData from '@/views/Reports/Insurance/Reminders/filterCard.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'
import InsuranceReminderReportStatistics from '@/views/Reports/Insurance/Reminders/IstaticticsCard.vue'

export default {
  name: 'ReminderReport',
  components: {
    InsuranceReminderReportStatistics,
    BLink,
    BAlert,
    BCardFooter,
    BTable,
    BPagination,
    PreLoading,
    ReminderFilterData,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      dataQuery: {
        limit: 25,
        start: 0,
      },
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
          thClass: 'align-middle',
        },
        {
          key: 'callCount',
          label: 'Poliçe Sayısı',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'username',
          label: 'Danışman',
          thClass: 'w-25',
        },
      ],
    }
  },
  computed: {
    reportData() {
      return this.$store.getters['insuranceReminderReport/dataList']
    },
    filterData() {
      return this.$store.getters['insuranceReminderReport/getFilterData']
    },
    reportLoading() {
      return this.$store.getters['insuranceReminderReport/dataLoading']
    },
    dataCount() {
      return this.$store.getters['insuranceReminderReport/dataCounts']
    },
    downloadURL() {
      const urlParams = []
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_com_user) {
        urlParams.push(`id_com_user=${this.filterData.id_com_user}`)
      }
      if (this.filterData.status) {
        urlParams.push(`status=${this.filterData.status}`)
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/insurance/Reminder_export/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getReport()
      }
    },
    getReport() {
      if (this.filterData) {
        this.dataQuery = { ...this.dataQuery, ...this.filterData }
        this.$store.dispatch('insuranceReminderReport/getReport', this.dataQuery)
      }
    },
  },
}
</script>
