<template>
  <b-card>
    <b-row>
      <b-col>
        <item-statistics
          title="Aranmış"
          :value="Number(istatistics.calls)"
          color="light-success"
          icon="PhoneCallIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Arama Bekleyen"
          :value="Number(istatistics.pending)"
          color="light-info"
          icon="WatchIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Toplam"
          :value="Number(istatistics.total)"
          color="light-warning"
          icon="PieChartIcon"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import ItemStatistics from '@/views/Crm/components/ItemStatistics.vue'

export default {
  name: 'InsuranceReminderReportStatistics',
  components: {
    BCol, BRow, ItemStatistics, BCard,
  },
  computed: {
    istatistics() {
      return this.$store.getters['insuranceReminderReport/dataIstatistics']
    },
  },
}
</script>
